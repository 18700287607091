import React, { useState } from "react";
import * as tasksAction from "../../../redux/actions/tasksAction";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { connect } from "react-redux";

function TaskForm({ addTask }) {
  const navigate = useNavigate();
  const assignedBy = ["Hamid Said", "Ibrahim Bashir"];
  const assignedTo = [
    "Hamid Said",
    "Ibrahim Bashir",
    "Mohammed Oumer",
    "Mekonnen Geremew",
    "Muluken Demas",
  ];
  const [taskObj, setTaskObj] = useState({
    assignedBy: "",
    assignedTo: "",
    taskTopic: "",
    taskDetails: "",
    taskStatus: "To Do",
    taskETA: "",
  });

  function hundleAddTask(e) {
    e.preventDefault();
   addTask(taskObj)
    setTaskObj({
      assignedBy: "",
      assignedTo: "",
      taskTopic: "",
      taskDetails: "",
      taskStatus: "To Do",
      taskETA: "",
    });
    toast.success(" Task Added Successfully");
    navigate("/dashboard/tasks");
  }

  function onchange(e) {
    const { name, value } = e.target;
    setTaskObj((prev) => {
      return {
        ...prev,
        [name]: value,
      };
    });
  }

  return (
    <form
      style={{ marginTop: 20, width: "70%" }}
      className="container-fluid"
      onSubmit={hundleAddTask}
    >
      <h3>Add Task</h3>
      <div className="mb-3 " style={{ width: "40%" }}>
        <label>Assigned By</label>
        <select className="form-select" name="assignedBy" onChange={onchange}>
          <option selected>Open this select menu</option>
          {assignedBy.map((item) => {
            return <option value={item} key={item}>{item}</option>;
          })}
        </select>
      </div>
      <div className="mb-3 " style={{ width: "40%" }} onChange={onchange}>
        <label>Assigned To</label>
        <select className="form-select" name="assignedTo">
          <option selected>Open this select menu</option>
          {assignedTo.map((item) => {
            return <option value={item} key={item}>{item}</option>;
          })}
        </select>
      </div>
      <div className="mb-3 " style={{ width: "40%" }}>
        <label>Task Topic</label>
        <input
          type="text"
          className="form-control"
          name="taskTopic"
          onChange={onchange}
        />
      </div>
      <div className="mb-3 " style={{ width: "40%" }}>
        <label>Task Details</label>
        <textarea
          type="text"
          className="form-control"
          name="taskDetails"
          onChange={onchange}
        />
      </div>
      <div className="mb-3 " style={{ width: "40%" }}>
        <label>ETA</label>
        <input
          type="date"
          className="form-control"
          name="taskETA"
          onChange={onchange}
        />
      </div>
      <button className="btn btn-success" type="submit">
        Add
      </button>
    </form>
  );
}

function mapStateToProps(state, ownProps) {
  return {
  };
}

const mapDispatchToProps = {
  addTask: tasksAction.addTask,
  deleteTask: tasksAction.deleteTask,
};

export default connect(mapStateToProps, mapDispatchToProps)(TaskForm);
