import React, { useState, useEffect } from "react";
import LoginForm from "./LoginForm";
import * as usersAction from "../../redux/actions/usersAction";
import * as logInAction from "../../redux/actions/logInAction";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
// eslint-disable-next-line no-useless-escape
var emailRegex = /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;

function LogInPage({ users, loadUsers, userSignIn, userStatus }) {
  const navigate = useNavigate();
  const [errors, setErrors] = useState();
  const [saving, setSaving] = useState(true);
  const [user, setUser] = useState({
    email: "",
    pwd: "",
  });

  //Loading the Users
  useEffect(() => {
    if (users.length === 0) {
      loadUsers();
    }
  });
  useEffect(() => {
    if (user.email !== "" && user.pwd !== "") {
      setSaving(false);
    } else {
      setSaving(true);
    }
  }, [user.email, user.pwd]);

  function onChange(event) {
    let { name, value } = event.target;

    setUser((prev) => {
      return {
        ...prev,
        [name]: value,
      };
    });
  }
  function validateEmail(email) {
    //Validates the email address
    return emailRegex.test(email);
  }
  function formIsValid() {
    setSaving(false);
    const { email, pwd } = user;
    const errors = {};

    if (!email) errors.email = "Email is required.";
    if (!validateEmail(email)) errors.email = "Invalid Email .";
    //if (!validateEmail(email)) errors.email = "Invalid Email";
    if (!pwd) errors.pwd = "Password is required";

    setErrors(errors);
    // Form is valid if the errors object still has no properties
    return Object.keys(errors).length === 0;
  }

  function onSave(event) {
    event.preventDefault();
    if (!formIsValid()) return;
    if (users.length === 0) {
      const errors = {};
      errors.onSave =
        "Sorry there is a problem loading users due to server overload or unavailablity , Please try later";
      setErrors(errors);
      return;
    }
    const loggedInUser = users.find((staff) => {
      return staff?.email === user.email && staff?.pwd === user.pwd;
    });
    if (loggedInUser) {
      userSignIn(loggedInUser);
      navigate("/dashboard");
      localStorage.setItem(
        "prefrance",
        JSON.stringify({
          userName: loggedInUser.fullName,
          loggedIn: true,
          role: loggedInUser?.role,
        })
      );
    } else {
      const errors = {};
      errors.onSave =
        "Sorry User Not Found , Please use the correct email and password";
      setErrors(errors);
    }
    setUser({
      email: "",
      pwd: "",
    });
  }
  return (
    <div className="container-fluid" style={{ width: "40%", marginTop: 50 }}>
      <LoginForm
        user={user}
        onSave={onSave}
        onChange={onChange}
        saving={saving}
        errors={errors}
      />
    </div>
  );
}
function mapStateToProps(state, ownProps) {
  return {
    users: state.users,
    userStatus: state.userStatus,
  };
}

const mapDispatchToProps = {
  loadUsers: usersAction.loadUsers,
  userSignIn: logInAction.userSignIn,
};
export default connect(mapStateToProps, mapDispatchToProps)(LogInPage);
