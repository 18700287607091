import * as types from "../actions/actionTypes";
import { initialSate } from "./initialState";

export default function coursesReducer(state = initialSate.courses, action) {
  switch (action.type) {
    case types.LOAD_COURSES_SUCCESS:
      return action.courses;
    case types.CREATE_COURSE_SUCCESS:
      return [...state, { ...action.course }];
    default:
      return state;
  }
}
