import * as types from "./actionTypes";

export function userLogIn(user) {
  return {
    type: types.USER_LOG_IN,
    user,
  };
}

export function userSignIn(user) {
  return function (dispatch) {
    dispatch(userLogIn(user));
  };
}

export function userLogOut() {
  return {
    type: types.USER_LOG_OUT,
  };
}

export function userSignOut() {
  return function (dispatch) {
    dispatch(userLogOut());
  };
}
