import React, { useEffect } from "react";
import { connect } from "react-redux";
import * as tasksAction from "../../../redux/actions/tasksAction";
import { useNavigate } from "react-router-dom";

function TasksBoard({ tasks, loadTasks, addTask, deleteTask }) {
  let count = 1;
  const navigate = useNavigate();

  useEffect(() => {
    loadTasks();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  function hundleCompleteTask(task) {
    let obj = { ...task };
    obj.taskStatus = "Completed";
    addTask(obj);
  }
  function hundleUpdateTask(task) {
   console.log('utility will be implemented in the future')
  }
  return (
    <>
      <button
        className="btn btn-success"
        onClick={() => {
          navigate("/dashboard/addtask");
        }}
      >
        Add Task
      </button>
      <table
        className=" table table-striped container-fluid"
        style={{ marginTop: 20, width: "80%" }}
      >
        <thead>
          <tr>
            <th scope="col">No.</th>
            <th scope="col">Assigned By</th>
            <th scope="col">Assigned To</th>
            <th scope="col">Task Topic</th>
            <th scope="col">Task Details</th>
            <th scope="col">Task Status</th>
            <th scope="col">ETA</th>
          </tr>
        </thead>
        <tbody>
          {tasks.map((task) => {
            return (
              <tr key={task.id}>
                <td>{count++}</td>
                <td>{task.assignedBy}</td>
                <td>{task.assignedTo}</td>
                <td>{task.taskTopic}</td>
                <td>{task.taskDetails}</td>
                <td>{task.taskStatus}</td>
                <td>{task.taskETA}</td>
                <td>
                  <button
                    className="btn btn-success"
                    onClick={() => {
                      hundleCompleteTask(task);
                    }}
                    disabled={task.taskStatus === "Completed"}
                  >
                    Complete
                  </button>
                </td>
                <td>
                  <button
                    className=" btn btn-success"
                    onClick={() => {
                      hundleUpdateTask(task);
                    }}
                  >
                    Edit
                  </button>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </>
  );
}

function mapStateToProps(state, ownProps) {
  return {
    tasks: state.tasks,
  };
}

const mapDispatchToProps = {
  loadTasks: tasksAction.loadTasks,
  addTask: tasksAction.addTask,
  deleteTask: tasksAction.deleteTask,
};

export default connect(mapStateToProps, mapDispatchToProps)(TasksBoard);
