import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import * as studentsAction from "../../../redux/actions/studentsAction";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import SignUpuForm from "../../signup/SignUpForm";

function ManageStudent({ students, registerStudent }) {
  const id = useParams().id;
  const navigate = useNavigate();
  // eslint-disable-next-line no-unused-vars
  const [errors, setErrors] = useState();
  const [saving, setSaving] = useState(false);
  const [student, setStudent] = useState();
  useEffect(() => {
    const studentToEdit = students.find((stu) => {
      return stu.id === id;
    });
    if (studentToEdit) {
      setStudent(studentToEdit);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  function onChange(event) {
    let { name, value } = event.target;

    setStudent((prev) => {
      return {
        ...prev,
        [name]: value,
      };
    });
  }
  function onSave(e) {
    e.preventDefault();
    setSaving(true);
    registerStudent(student);
    navigate("/dashboard/students");
    toast.success('Student Updated Successfully');
  }

  return (
    <div className="container-fluid" style={{ width: "40%", marginTop: 50 }}>
      <SignUpuForm
        student={ student ? student : {
    email: "",
    fName: "",
    lName: "",
    phone: "",
    id: ""
}}
        onSave={onSave}
        onChange={onChange}
        saving={saving}
        errors={errors}
        register="Update"
      />
    </div>
  );
}

function mapStateToProps(state, ownProps) {
  return {
    students: state.students,
  };
}

const mapDispatchToProps = {
  registerStudent: studentsAction.registerStudent,
};

export default connect(mapStateToProps, mapDispatchToProps)(ManageStudent);
