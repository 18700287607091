import * as types from "../actions/actionTypes";
import { initialSate } from "./initialState";

export default function messagesReducer(state = initialSate.messages, action) {
  switch (action.type) {
    case types.LOAD_MESSAGES_SUCCESS:
      return action.messages;
    case types.CREATE_NEW_MESSAGE_SUCCESS:
      return [...state, { ...action.message }];
    default:
      return state;
  }
}
