import { handleResponse, handleError } from "./apiUtils";
import axios from "axios";
const baseUrl =
  "https://anispro-user-database-default-rtdb.firebaseio.com/students.json";

export function getStudents() {
  return axios.get(baseUrl).then(handleResponse).catch(handleError);
}

export function saveStudent(student) {
  let id = student?.id;
  const updateUrl = `https://anispro-user-database-default-rtdb.firebaseio.com/students/${id}.json`;
  if(id) {
    return axios.put(updateUrl, student).then(handleResponse).catch(handleError);
  }else {
    return axios.post(baseUrl, student).then(handleResponse).catch(handleError);
  }
}

export function deleteStudent(key) {
  const deleteUrl = `https://anispro-user-database-default-rtdb.firebaseio.com/students/${key}.json`;
  return axios.delete(deleteUrl).then(handleResponse).catch(handleError);
}
