export const initialSate = {
  students: [],
  users: [],
  courses: [],
  apiCallsInProgress: 0,
  userStatus: { loggedIn: false },
  messages: [],
  customerMessages: [],
  tasks: [],
};
