import * as types from "../actions/actionTypes";
import { initialSate } from "./initialState";

export default function tasksReducer(state = initialSate.tasks, action) {
  switch (action.type) {
    case types.LOAD_TASKS_SUCCESS:
      return action.tasks;
    case types.ADD_TASK_SUCCESS:
      return [...state, { ...action.task }];
    case types.UPDATE_TASK_SUCCESS:
      return state.map((task) => {
        return task.id === action.task.id ? action.task : task;
      });
    case types.DELETE_TASK_SUCCESS:
      return state.filter((task) => {
        return task.id !== action.id;
      });
    default:
      return state;
  }
}
