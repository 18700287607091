import React, { useEffect } from "react";
import * as studentsAction from "../../../redux/actions/studentsAction";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
function StudentsPage({ students, loadStudents, deleteStudent }) {
   const navigate = useNavigate();
  // load students
  useEffect(() => {
    if (students.length === 0) loadStudents();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function hundleDeleteStudent(stu) {
    const del = window.confirm(` Do you really want to delete ${stu.fName} ${stu.lName}`);
    if(del) {
      deleteStudent(stu.id);
      toast.success(" Student Deleted Successfully");
    }else {
      return 
    }
  }
  function hundleStudentEdit(stu) {
    navigate(`/dashboard/students/${stu.id}`,{state:stu});
  }

  return (
    <table
      className=" table table-striped container-fluid"
      style={{ marginTop: 20, width: "80%" }}
    >
      <thead>
        <tr>
          <th scope="col">First Name</th>
          <th scope="col">Last Name</th>
          <th scope="col">Email</th>
          <th scope="col">Phone Number</th>
          <th scope="col">Payment Status</th>
        </tr>
      </thead>
      <tbody>
        {students.map((stu) => {
          return (
            <tr key={stu.id}>
              <td>{stu.fName}</td>
              <td>{stu.lName}</td>
              <td>{stu.email}</td>
              <td>{stu.phone}</td>
              <td>{stu.paymentStatus ? stu.paymentStatus : "N/A"}</td>
              <td>
                <button className=" btn btn-success" onClick={() => {hundleStudentEdit(stu)}}>Edit</button>
              </td>
              <td>
                <button
                  className="btn btn-danger"
                  onClick={() => {
                    hundleDeleteStudent(stu);
                  }}
                >
                  Delete
                </button>
              </td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
}

function mapStateToProps(state, ownProps) {
  return {
    students: state.students,
  };
}

const mapDispatchToProps = {
  loadStudents: studentsAction.loadStudents,
  deleteStudent: studentsAction.deleteStudent,
};

export default connect(mapStateToProps, mapDispatchToProps)(StudentsPage);
