import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Link, Outlet } from "react-router-dom";
import * as logInAction from "../../redux/actions/logInAction";
import { useNavigate } from "react-router-dom";

function AnisTechBoard({ userStatus, userSignOut }) {
  const forAllStaff = (
    <>
      <Link to="/dashboard/tasks" style={{ marginRight: 5 }}>
        Task Board
      </Link>{" "}
      {" | "}
      <Link to="/dashboard/messages">Message Board</Link>{" "}
    </>
  );
  const forAdmin = (
    <>
      {" | "}
      <Link to="/dashboard/students">Students</Link> {" | "}
      <Link to="/dashboard/contactusmessages">Custamore Messages</Link>
    </>
  );
  const navigate = useNavigate();
  const [userName, setUserName] = useState("");
  const [loggedIn, setLoggedIn] = useState();
  const [role, setRole] = useState();

  useEffect(() => {
    const user = localStorage["prefrance"]
      ? JSON.parse(localStorage.getItem("prefrance")).userName
      : userStatus?.user?.fullName;
    setUserName(user);
    const loggedIn = localStorage["prefrance"]
      ? JSON.parse(localStorage.getItem("prefrance")).loggedIn
      : userStatus?.loggedIn;
    setLoggedIn(loggedIn);
    const role = localStorage["prefrance"]
      ? JSON.parse(localStorage.getItem("prefrance")).role
      : userStatus?.user?.role;
    setRole(role);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function hundleLogOut(event) {
    event.preventDefault();
    localStorage.removeItem("prefrance");
    userSignOut();
    navigate("/home");
  }
  function hundleAddNewUser() {
    navigate("/dashboard/adduser");
  }
  return (
    <div className="container-fluid" style={{ marginTop: 20 }}>
      <div>
        {loggedIn ? (
          <div className="hstack gap-3">
            <h3 className="p-2" style={{ color: "burlywood" }}>
              {" "}
              Welcome Back {userName}
            </h3>
            <button
              className="btn btn-primary p-2 ms-auto btn-lg"
              onClick={hundleLogOut}
            >
              Logout
            </button>{" "}
            <button
              className="btn btn-primary p-2 btn-lg"
              onClick={hundleAddNewUser}
              disabled={role !=='admin'}
            >
              Add New User
            </button>{" "}
          </div>
        ) : (
          <h3> Please Login First ...</h3>
        )}
        <nav style={{ textAlign: "center" }}>
          {loggedIn && forAllStaff}
          {loggedIn && role === "admin" && forAdmin}
        </nav>
      </div>
      <Outlet />
    </div>
  );
}
function mapSateToProps(state, ownprops) {
  return {
    userStatus: state.userStatus,
  };
}

const mapDispatchToProp = {
  userSignOut: logInAction.userSignOut,
};
export default connect(mapSateToProps, mapDispatchToProp)(AnisTechBoard);
