import React from "react";
import TextInput from "../../common/TextInput";

const UserForm = ({
  user,
  onSave,
  onChange,
  saving = false,
  errors = {},
  register = "Register",
}) => {
  return (
    <form onSubmit={onSave}>
      <h4>
        <small>Welcome To AnisTech Professional UIMS</small>
      </h4>
      <h5>
        <small>Add New User</small>
      </h5>
      {errors.onSave && (
        <div className="alert alert-danger" role="alert">
          {errors.onSave}
        </div>
      )}
      <TextInput
        name="fullName"
        label="Full Name"
        value={user.fullName}
        onChange={onChange}
        error={errors.fullName}
      />
      <TextInput
        name="email"
        label="Email Address"
        value={user.email}
        onChange={onChange}
        error={errors.email}
      />
      <TextInput
        name="pwd"
        label="Password"
        value={user.pwd}
        onChange={onChange}
        error={errors.pwd}
      />
      <TextInput
        name="role"
        label="Role"
        value={user.role}
        onChange={onChange}
        error={errors.role}
      />

      <button
        type="submit"
        disabled={saving}
        className="btn btn-primary btn-lg"
      >
        {register}
      </button>
    </form>
  );
};

export default UserForm;
