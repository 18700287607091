import React, { useState, useEffect } from "react";
import SignUpuForm from "./SignUpForm";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { connect } from "react-redux";
import * as studentsAction from "../../redux/actions/studentsAction";
// eslint-disable-next-line no-useless-escape
var emailRegex = /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;
var phoneNumberPattern = /^\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})$/;

function SignUpPage({ registerStudent, students, loadStudents }) {
  const navigate = useNavigate();
  const [errors, setErrors] = useState();
  const [saving, setSaving] = useState(true);
  const [student, setStudent] = useState({
    fName: "",
    lName: "",
    email: "",
    phone: "",
  });

  // load students
  useEffect(() => {
    if (students.length === 0) loadStudents();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (
      student.fName !== "" &&
      student.lName !== "" &&
      student.email !== "" &&
      student.phone !== ""
    ) {
      setSaving(false);
    } else {
      setSaving(true);
    }
  }, [student.fName, student.lName, student.email, student.phone]);

  function onChange(event) {
    let { name, value } = event.target;

    setStudent((prev) => {
      return {
        ...prev,
        [name]: value,
      };
    });
  }

  function validateEmail(email) {
    //Validates the email address
    return emailRegex.test(email);
  }

  function validatePhone(phoneNumber) {
    return phoneNumberPattern.test(phoneNumber);
  }
  function formIsValid() {
    setSaving(false);
    const { fName, lName, email, phone } = student;
    const errors = {};

    if (!fName || fName.trim() === "") errors.fName = "First Name is required.";
    if (!lName || lName.trim() === "") errors.lName = "Last Name is required";
    if (!email || email.trim() === "") errors.email = "Email is required";
    if (!validateEmail(email)) errors.email = "Invalid Email";
    if (!phone || phone.trim() === "")
      errors.phone = "Phone Number is required";
    if (!validatePhone(phone)) errors.phone = " Invalid Phone Number";

    setErrors(errors);
    // Form is valid if the errors object still has no properties
    return Object.keys(errors).length === 0;
  }

  function studentExist(student) {
    let checkStudent = students?.find((stu) => {
      return (
        stu.fName === student.fName &&
        stu.lName === student.lName &&
        stu.email === student.email
      );
    });
    return checkStudent ? true : false;
  }

  function onSave(event) {
    event.preventDefault();
    if (!formIsValid()) return;
    if (studentExist(student)) {
      let errors = {};
      errors.onSave = `${student.fName} ${student.lName} with email id ${student.email} already exist. Please contact customer service`;
      setErrors(errors);
      setStudent({
        fName: "",
        lName: "",
        email: "",
        phone: "",
      });
      return;
    }
    registerStudent(student);
    toast.success(`Registration Completed.
     Some one will contact you soon. Thank you for choosing AnisTech Professional!!`);
    setStudent({
      fName: "",
      lName: "",
      email: "",
      phone: "",
    });
    navigate("/home");
  }

  return (
    <div className="container-fluid" style={{ width: "40%", marginTop: 50 }}>
      <SignUpuForm
        student={student}
        onSave={onSave}
        onChange={onChange}
        saving={saving}
        errors={errors}
      />
    </div>
  );
}
function mapStateToProps(state, ownProps) {
  return {
    students: state.students,
  };
}

const mapDispatchToProps = {
  registerStudent: studentsAction.registerStudent,
  loadStudents: studentsAction.loadStudents,
};
export default connect(mapStateToProps, mapDispatchToProps)(SignUpPage);
