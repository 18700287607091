import * as types from "../actions/actionTypes";
import { initialSate } from "./initialState";

export default function LogInReducer(state = initialSate.userStatus, action) {
  switch (action.type) {
    case types.USER_LOG_IN:
      return { ...state, loggedIn:true, user:action.user};
    case types.USER_LOG_OUT:
      return {...state , loggedIn:false,user:{}}
      
    default:
      return state;
  }
}
