import React from "react";
import Header from "./components/navigation-bar/Header.js";
import HomePage from "./components/home/HomePage.js";
import { Routes, Route } from "react-router-dom";
import AboutPage from "./components/about/AboutPage.js";
import StudentsPage from "./components/ProtectedPage/students/StudentsPage.js";
import LogInPage from "./components/login/LoginPage.js";
import SignUpPage from "./components/signup/SignUpPage.js";
import ServicesPage from "./components/services/ServicesPage.js";
import AddCoursePage from "./components/courses/AddCoursePage.js";
import CoursesPage from "./components/courses/CoursesPage.js";
import ContactUsPage from "./components/contactus/ContactUsPage.js";
import PageNotFound from "./components/PageNoteFound.js";
import AnisTechBoard from "./components/ProtectedPage/AnisTechBoard.js";
import TasksBoard from "./components/ProtectedPage/tasks/TasksBoard.js";
import MessagesBoard from "./components/ProtectedPage/messageBoard.js";
import CustomerMessages from "./components/ProtectedPage/CustomerMessages.js";
import ManageStudent from "./components/ProtectedPage/students/ManageStudent.js";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import TaskForm from "./components/ProtectedPage/tasks/taskForm.js";
import addNewUser from "./components/ProtectedPage/newUser/addNewUser.js";

function App() {
  return (
    <div className="container-fluid">
      <Header />
      <Routes>
        <Route exact path="/">
          <Route index Component={HomePage}></Route>
          <Route path="home" Component={HomePage}></Route>
        </Route>
        <Route path="aboutus" Component={AboutPage} />
        <Route path="student" Component={StudentsPage} />
        <Route path="login" Component={LogInPage} />
        <Route path="signup" Component={SignUpPage} />
        <Route path="services" Component={ServicesPage}>
        </Route>
        <Route path="contactus" Component={ContactUsPage} />
        <Route path="courses" Component={CoursesPage} >
          <Route path="addcourse" Component={AddCoursePage}/>
        </Route>
        <Route path="dashboard" Component={AnisTechBoard}>
          <Route index Component={TasksBoard}></Route>
          <Route path="tasks" Component={TasksBoard} />
          <Route path="adduser" Component={addNewUser} />
          <Route path="addtask" Component={TaskForm} />
          <Route path="messages" Component={MessagesBoard} />
          <Route path="students" Component={StudentsPage} />
          <Route path="students/:id" Component={ManageStudent} />
          <Route path="contactusmessages" Component={CustomerMessages} />
        </Route>
        <Route path="*" Component={PageNotFound}></Route>
      </Routes>
      <ToastContainer autoClose={5000} hideProgressBar />
    </div>
  );
}

export default App;
