import React from "react";
import TextInput from "../common/TextInput";
import { useNavigate } from "react-router-dom";
const CourseForm = ({ course, onSave, onChange, saving = false, errors = {} }) => {
   let navigate = useNavigate();
  function hundleCloseForm(){
    navigate("/courses");
  }
  return (
    <form onSubmit={onSave}>
      <div class="modal-header">
        <h5 class="modal-title">Add Course</h5>
        <button
          type="button"
          class="btn-close"
          data-bs-dismiss="modal"
          aria-label="Close"
          onClick={hundleCloseForm}
        ></button>
      </div>{" "}
      {errors.onSave && (
        <div className="alert alert-danger" role="alert">
          {errors.onSave}
        </div>
      )}
      <TextInput
        name="serviceName"
        label="Course Name"
        value={course.serviceName}
        onChange={onChange}
        error={errors.serviceName}
      />
      <TextInput
        name="imgUrl"
        label="Image URL"
        value={course.imgUrl}
        onChange={onChange}
        error={errors.imgUrl}
      />
      <TextInput
        name="desc"
        label="Description"
        value={course.desc}
        onChange={onChange}
        error={errors.desc}
      />
      <button
        type="submit"
        disabled={saving}
        className="btn btn-primary btn-lg"
      >
        Add Course
      </button>
    </form>
  );
};

export default CourseForm;
