import * as types from "./actionTypes";
import * as tasksApi from "../../api/tasksApi";

export function loadTasksSuccess(tasks) {
  return {
    type: types.LOAD_TASKS_SUCCESS,
    tasks,
  };
}

export function loadTasks() {
  return function (dispatch) {
    return tasksApi
      .getTasks()
      .then((resp) => {
        let arr = [];
        if (resp) {
          for (let key in resp.data) {
            arr.push({ ...resp.data[key], id: key });
          }
          dispatch(loadTasksSuccess(arr));
        }
      })
      .catch((err) => {
        throw new Error(err);
      });
  };
}

export function addTaskSuccess(task) {
  return {
    type: types.ADD_TASK_SUCCESS,
    task,
  };
}
export function updateTaskSuccess(task) {
  return {
    type: types.UPDATE_TASK_SUCCESS,
    task,
  };
}

export function addTask(task) {
  return function (dispatch) {
    return tasksApi
      .saveTask(task)
      .then((resp) => {
        const savedTask = { ...task, id: resp.data.name };
        task.id
          ? dispatch(updateTaskSuccess(task))
          : dispatch(addTaskSuccess(savedTask));
      })
      .catch((err) => {
        throw err;
      });
  };
}

export function deleteTaskSuccess(id) {
  return {
    type: types.DELETE_TASK_SUCCESS,
    id,
  };
}

export function deleteTask(id) {
  return function (dispatch) {
    return tasksApi
      .deleteTask(id)
      .then((resp) => {
        if (resp) {
          dispatch(deleteTaskSuccess(id));
        }
      })
      .catch((err) => {
        throw new Error(err);
      });
  };
}
