import React from "react";
import { Link } from "react-router-dom";

function HomePage() {
   return (
     <div className="container-fluid" style={{ marginTop: 20 }}>
       <div className="card-group">
         <div className="card w-50 mb-3">
           <img
             className="card-img-top"
             alt="logo192.png"
             src="logo192.png"
             style={{ height: 270}}
           />
           <div className="card-body">
             <h5 className="card-title text-success display-6">
               Promoting Independence In Learning !!
             </h5>
             <p className="card-text">
               AnisTech Professionals Online Bootcamp Are Providing Extensive
               Hand-on Experience And Skills On JavaScript Related Technologies
               That Are Needed To Succeed In Today's Competitive Job Market.
             </p>
             <div className="btn-group d-flex">
               <Link to="/signup" className="btn btn-primary">
                 Join Us
               </Link>
             </div>
           </div>
         </div>

         <div className="card text-bg-dark" style={{ marginBottom: 15 }}>
           <img
             src="https://symbiosised.com/wp-content/uploads/2020/02/shutterstock_1139995139-1-768x436.jpg"
             className="card-img"
             alt="..."
             style={{ height: "100%" }}
           />
           <div className="card-img-overlay">
             <h5 className="card-title">
               AnisTech Professionals Online Bootcamp
             </h5>
             <p className="card-text">
               Join Us And Lets Make Your Dream Come True !!
             </p>
             <p className="card-text">New Class Will Start Soon !!</p>
             <p className="card-text">
               Call Us On 202-658-0378 For More Information.
             </p>
           </div>
         </div>
       </div>
     </div>
   );
}

export default HomePage;
