import React, { useEffect } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import * as coursesAction from "../../redux/actions/coursesAction";

function CoursePage({ courses, loadCourses }) {
   let cridentilas = localStorage.getItem("prefrance");
   let auth = JSON.parse(cridentilas);
   let navigate = useNavigate()

  function hundleCoursedetail(course) {
    console.log(course);
  }
 function hundleNavigationToAddCourse(e) {
    console.log("navigating to Add Course");
    e.preventDefault();
    navigate("/courses/addcourse");
  }
  useEffect(() => {
    if (courses.length === 0) {
      loadCourses();
    }
  });
  return (
    <>
      <Outlet />
      <div className="hstack gap-3">
        <h3 className="text-primary " style={{ marginTop: 20 }}>
          Currently Active Trainings{" "}
        </h3>
        {auth?.role === "admin" && (
          <button
            className="btn btn-primary p-2 ms-auto btn-lg"
            onClick={hundleNavigationToAddCourse}
          >
            Add Course
          </button>
        )}
      </div>
      <div className="card-group" style={{ marginTop: 10 }}>
        {courses?.map((course) => {
          return (
            <div
              style={{ marginTop: 20 }}
              key={course.id}
              className="card mb-3 bg-light"
              onClick={() => hundleCoursedetail(course)}
            >
              <img src={course.imgUrl} className="card-img-top" alt="..." />
              <div className="card-body">
                <h5 className="card-title">{course.serviceName}</h5>
                <p className="card-text">{course.desc}</p>
              </div>
            </div>
          );
        })}
      </div>
    </>
  );
}
function mapStateToProps(state, ownprops) {
  return {
    courses: state.courses,
  };
}

const mapDispatchToProps = {
  loadCourses: coursesAction.loadCourses,
};
export default connect(mapStateToProps, mapDispatchToProps)(CoursePage);
