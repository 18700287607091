import * as types from "./actionTypes";
import * as usersApi from "../../api/usersApi";

export function loadUsersSuccess(users) {
  return {
    type: types.LOAD_USERS_SUCCESS,
    users,
  };
}

export function loadUsers() {
  return function (dispatch) {
    return usersApi
      .getUsers()
      .then((resp) => {
        if (resp) {
          let arr = [];
          for (let key in resp.data) {
            arr.push({ ...resp.data[key], id: key });
          }
          dispatch(loadUsersSuccess(arr));
        }
      })
      .catch((err) => {
        throw new Error(err);
      });
  };
}

export function addUserSuccess(user) {
  return {
    type: types.ADD_USER_SUCCESS,
    user,
  };
}
export function updateUserSuccess(user) {
  return {
    type: types.UPDATE_USER_SUCCESS,
    user,
  };
}

export function addUser(user) {
  return function (dispatch) {
    return usersApi
      .saveUser(user)
      .then((resp) => {
        const savedUser = { ...user, id: resp.data.name };
        user.id
          ? dispatch(updateUserSuccess(user))
          : dispatch(addUserSuccess(savedUser));
      })
      .catch((err) => {
        throw err;
      });
  };
}
