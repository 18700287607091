import { handleResponse, handleError } from "./apiUtils";
import axios from "axios";
const baseUrl =
  "https://anispro-user-database-default-rtdb.firebaseio.com/customermessages.json";

export function getCustomerMessages() {
  return axios.get(baseUrl).then(handleResponse).catch(handleError);
}

export function saveCustomerMessage(message) {
  return axios.post(baseUrl, message).then(handleResponse).catch(handleError);
}

export function deleteMessage(key) {
  const baseUrl = `https://anispro-user-database-default-rtdb.firebaseio.com/customermessages/${key}.json`;
  return axios.delete(baseUrl).then(handleResponse).catch(handleError);
}
