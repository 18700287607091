import { handleResponse, handleError } from "./apiUtils";
import axios from "axios";
const baseUrl = "https://anispro-user-database-default-rtdb.firebaseio.com/admins.json";

export function getUsers() {
  return axios.get(baseUrl).then(handleResponse).catch(handleError);
}

export function saveUser(user) {
    if(user.id){
        return axios.put(baseUrl,user)
         .then(handleResponse)
         .catch(handleError);
    }else {
        return axios.post(baseUrl, user)
        .then(handleResponse)
        .catch(handleError);
    }
  
    
}

export function deleteCourse(UserId) {
    return axios.delete(baseUrl,UserId)
    .then(handleResponse)
    .catch(handleError);
}
