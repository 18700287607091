import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import * as messagesAction from "../../redux/actions/messageAction";

function MessagesBoard({ messages, loadMessages, saveMessage, userStatus }) {
  const [status, setStatus] = useState("btn btn-primary");
  const [newMessage, setNewMessage] = useState("");
  const [userName, setUserName] = useState("");

  useEffect(() => {
    if (newMessage === "") {
      setStatus(" btn btn-primary disabled");
    } else {
      setStatus("btn btn-primary");
    }
  }, [newMessage]);

  useEffect(() => {
    const user = localStorage["prefrance"]
      ? JSON.parse(localStorage.getItem("prefrance")).userName
      : userStatus?.user?.fullName;
    setUserName(user);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (messages.length === 0) {
      loadMessages();
    }
  });

  function hundleStatusChange(e) {
    if (e.target.value === "ONLINE" && newMessage !== "") {
      setStatus("btn btn-primary");
    } else {
      setStatus(" btn btn-primary disabled");
    }
  }

  function hundleNewMessage(e) {
    setNewMessage(e.target.value);
  }

  function hundleSaveMessage(e) {
    e.preventDefault();
    let obj = {
      userName,
      newMessage,
    };
    saveMessage(obj);
    setNewMessage("");
  }

  return (
    <div className="container-fluid" style={{ marginTop: 20 }}>
      <div className="hstack gap-3">
        <h1 className="p-2">Message Board</h1>
        <div className="p-2">
          <form className="form-inline" name="selectStatus">
            <select
              name="status"
              id=""
              className="custom-select"
              onChange={hundleStatusChange}
            >
              <option value="ONLINE">Online</option>
              <option value="AWAY">Away</option>
              <option value="BUSY">Busy</option>
              <option value="OFFLINE">Offline</option>
            </select>
          </form>
        </div>
      </div>
      <div className="form-floating">
        <section className="card w-50 mb-3">
          <label htmlFor="floatingTextarea2">Messages</label>
          {messages.map((message) => {
            return (
              <span key={message.id}>
                {message.userName + " :  " + message.newMessage}
              </span>
            );
          })}
        </section>
      </div>

      <form
        onSubmit={hundleSaveMessage}
        className="form-inline"
        name="newMessage"
        style={{ width: "45%", marginTop: 10 }}
      >
        <fieldset name="fields">
          <input
            onChange={hundleNewMessage}
            value={newMessage}
            type="text"
            className="form-control"
            name="newMessage"
            placeholder="Type a new message"
          />
          <button type="submit" className={status}>
            Send Message
          </button>
        </fieldset>
      </form>
    </div>
  );
}
function mapStateToProps(state, ownprops) {
  return {
    messages: state.messages,
    userStatus: state.userStatus,
  };
}

const mapDispatchToProps = {
  loadMessages: messagesAction.loadMessages,
  saveMessage: messagesAction.saveMessage,
};
export default connect(mapStateToProps, mapDispatchToProps)(MessagesBoard);
