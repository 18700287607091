import { handleResponse, handleError } from "./apiUtils";
import axios from "axios";
const baseUrl =
  "https://anispro-user-database-default-rtdb.firebaseio.com/courses.json";

export function getCourses() {
  return axios.get(baseUrl).then(handleResponse).catch(handleError);
}

export function saveCourse(course) {
    return axios.post(baseUrl, course).then(handleResponse).catch(handleError);
//   if (user.id) {
//     return axios.put(baseUrl, user).then(handleResponse).catch(handleError);
//   } else {
//     return axios.post(baseUrl, user).then(handleResponse).catch(handleError);
//   }
}

export function deleteCourse(courseId) {
  return axios.delete(baseUrl, courseId).then(handleResponse).catch(handleError);
}
