import React from "react";

function AboutPage() {
  return (
    <div className="container-fluid bg-light" style={{ marginTop: 20 }}>
      <div className="card w-75 mb-3">
        <div className="card-body">
          <h5 className="card-title text-primary display-6">About US</h5>
          <p className="card-text text-info">
            AnisTech Professional is an online Training and consulting IT
            company, born of the idea that if understanding paradigm of
            programming helps transforming ideas into software that allows
            availablity world wide. Why not we create a platform to train you at
            your convenient time.
          </p>
          <p className="card-text text-info">
            We are Experienced in IT industries in different domains and
            Providing extensive hand on experience and skills on JavaScript
            related technologies that are needed to succeed in today's
            competitive job market.
          </p>
        </div>
      </div>

      <div className="card w-50">
        <div className="card-body">
          <h5 className="card-title display-6 text-primary bg-ligth">
            For More Information{" "}
          </h5>
          <p className="card-text text-dark">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              className="bi bi-phone"
              viewBox="0 0 16 16"
            >
              <path d="M11 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h6zM5 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H5z" />
              <path d="M8 14a1 1 0 1 0 0-2 1 1 0 0 0 0 2z" />
            </svg>
            <i className="bi bi-envelope" style={{ marginLeft: 10 }}>
              +1 202-658-0378
            </i>
          </p>
          <p className="card-text">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              className="bi bi-envelope"
              viewBox="0 0 16 16"
            >
              <path d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V4Zm2-1a1 1 0 0 0-1 1v.217l7 4.2 7-4.2V4a1 1 0 0 0-1-1H2Zm13 2.383-4.708 2.825L15 11.105V5.383Zm-.034 6.876-5.64-3.471L8 9.583l-1.326-.795-5.64 3.47A1 1 0 0 0 2 13h12a1 1 0 0 0 .966-.741ZM1 11.105l4.708-2.897L1 5.383v5.722Z" />
            </svg>
            <i className="bi bi-envelope" style={{ marginLeft: 10 }}>
              contact@anispro.com
            </i>
          </p>
          <p className="card-text">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              className="bi bi-geo-alt"
              viewBox="0 0 16 16"
            >
              <path d="M12.166 8.94c-.524 1.062-1.234 2.12-1.96 3.07A31.493 31.493 0 0 1 8 14.58a31.481 31.481 0 0 1-2.206-2.57c-.726-.95-1.436-2.008-1.96-3.07C3.304 7.867 3 6.862 3 6a5 5 0 0 1 10 0c0 .862-.305 1.867-.834 2.94zM8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10z" />
              <path d="M8 8a2 2 0 1 1 0-4 2 2 0 0 1 0 4zm0 1a3 3 0 1 0 0-6 3 3 0 0 0 0 6z" />
            </svg>
            <i className="bi bi-envelope" style={{ marginLeft: 10 }}>
              1050 Lake Carolyn Pkwy suit 3348
            </i>
          </p>
          <p className="card-text">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              className="bi bi-search"
              viewBox="0 0 16 16"
            >
              <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" />
            </svg>
            <i className="bi bi-envelope" style={{ marginLeft: 10 }}>
              anispro.com
            </i>
          </p>
          <p className="card-text">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              className="bi bi-clock"
              viewBox="0 0 16 16"
            >
              <path d="M8 3.5a.5.5 0 0 0-1 0V9a.5.5 0 0 0 .252.434l3.5 2a.5.5 0 0 0 .496-.868L8 8.71V3.5z" />
              <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zm7-8A7 7 0 1 1 1 8a7 7 0 0 1 14 0z" />
            </svg>
            <i className="bi bi-envelope" style={{ marginLeft: 10 }}>
              Hours of operation
            </i>
          </p>
          <p className="card-text" style={{ marginLeft: 15 }}>
            Monday - Friday 9.00 am - 5.00 pm CST
          </p>
        </div>
      </div>
    </div>
  );
}

export default AboutPage;
