import React, { useState, useEffect } from "react";
import CourseForm from "./CourseForm";
import { connect } from "react-redux";
import * as coursesAction from "../../redux/actions/coursesAction";
import { useNavigate } from "react-router-dom";

function AddCoursePage({ saveCourse }) {
  const navigate = useNavigate();
  const [errors, setErrors] = useState();
  const [saving, setSaving] = useState(true);
  const [course, setCourse] = useState({
    serviceName: "",
    imgUrl: "",
    desc: "",
  });

  //Loading the user
  useEffect(() => {});
  useEffect(() => {
    if (
      course.serviceName !== "" &&
      course.imgUrl !== "" &&
      course.desc !== ""
    ) {
      setSaving(false);
    } else {
      setSaving(true);
    }
  }, [course.serviceName, course.imgUrl, course.desc]);

  function onChange(event) {
    let { name, value } = event.target;

    setCourse((prev) => {
      return {
        ...prev,
        [name]: value,
      };
    });
  }

  function formIsValid() {
    setSaving(false);
    const { serviceName, imgUrl, desc } = course;
    const errors = {};

    if (!serviceName) errors.serviceName = "Course Name is required.";
    if (!imgUrl) errors.imgUrl = "Image Url is required";
    if (!desc) errors.desc = "Description is required";

    setErrors(errors);
    // Form is valid if the errors object still has no properties
    return Object.keys(errors).length === 0;
  }

  function onSave(event) {
    event.preventDefault();
    if (!formIsValid()) return;
    saveCourse(course);
    setCourse({
      serviceName: "",
      imgUrl: "",
      desc: "",
    });
    navigate("/courses");
  }
  return (
    <div className="container-fluid" style={{ width: "40%", marginTop: 50 }}>
      <CourseForm
        course={course}
        onSave={onSave}
        onChange={onChange}
        saving={saving}
        errors={errors}
      />
    </div>
  );
}
function mapStateToProps(state, ownProps) {
  return {};
}

const mapDispatchToProps = {
  saveCourse: coursesAction.saveCourse,
};

export default connect(mapStateToProps, mapDispatchToProps)(AddCoursePage);
