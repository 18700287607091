import React from "react";
import { Link } from "react-router-dom";
function Navigation(){
    return (
      <>
        <ul className="navbar-nav">
          <li className="nav-item">
            <Link
              className="nav-link active text-primary"
              aria-current="page"
              to={`home`}
            >
              Home
            </Link>
          </li>
          <li className="nav-item">
            <Link
              className="nav-link active text-primary"
              aria-current="page"
              to={`services`}
            >
              Services
            </Link>
          </li>
          <li className="nav-item ">
            <Link
              className="nav-link active text-primary"
              aria-current="page"
              to={`aboutus`}
            >
              About Us
            </Link>
          </li>
          <li className="nav-item">
            <Link
              className="nav-link active text-primary"
              aria-current="page"
              to={`contactus`}
            >
              Contact Us
            </Link>
          </li>
        </ul>
        <form className="d-flex" role="search">
          <Link
            replace
            to={"login"}
            className="btn btn-outline-success"
            type="button"
          >
            Sign In
          </Link>
          <Link
            replace
            to={"signup"}
            className="btn btn-outline-success"
            type="button"
            style={{ marginLeft: 5 }}
          >
            Sign up
          </Link>
        </form>
      </>
    );
}

export default Navigation;