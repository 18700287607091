import * as types from "./actionTypes";
import * as customerApi from "../../api/customerApi";

export function loadCustomerMessagesSuccess(messages) {
  return {
    type: types.LOAD_CUSTOMER_MESSAGES_SUCCESS,
    messages,
  };
}

export function loadCustomerMessages() {
  return function (dispatch) {
    return customerApi
      .getCustomerMessages()
      .then((resp) => {
        let arr = [];
        if (resp) {
          for (let key in resp.data) {
            arr.push({ ...resp.data[key], id: key });
          }
          dispatch(loadCustomerMessagesSuccess(arr));
        }
      })
      .catch((err) => {
        throw new Error(err);
      });
  };
}

export function createCustomerMessageSuccess(message) {
  return {
    type: types.CREATE_NEW_CUSTOMER_MESSAGE_SUCCESS,
    message,
  };
}

export function saveCustomerMessage(message) {
  return function (dispatch) {
    return customerApi
      .saveCustomerMessage(message)
      .then((resp) => {
        const savedCustomerMessage = { ...message, id: resp.data.name };
        dispatch(createCustomerMessageSuccess(savedCustomerMessage));
      })
      .catch((err) => {
        throw err;
      });
  };
}


export function deleteCustomerMessageSuccess(id) {
  return {
    type: types.DELETE_CUSTOMER_MESSAGE_SUCCESS,
    id,
  };
}

export function deleteCustomerMessage(id) {
  return function (dispatch) {
    return customerApi
      .deleteMessage(id)
      .then((resp) => {
        if (resp) {
          dispatch(deleteCustomerMessageSuccess(id));
        }
      })
      .catch((err) => {
        throw new Error(err);
      });
  };
}

