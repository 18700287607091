import * as types from "./actionTypes";
import * as messagesApi from "../../api/messagesApi";

export function loadMessagesSuccess(messages) {
  return {
    type: types.LOAD_MESSAGES_SUCCESS,
    messages,
  };
}

export function loadMessages() {
  return function (dispatch) {
    return messagesApi
      .getMessages()
      .then((resp) => {
        let arr = [];
        if (resp) {
          for (let key in resp.data) {
            arr.push({ ...resp.data[key], id: key });
          }
          dispatch(loadMessagesSuccess(arr));
        }
      })
      .catch((err) => {
        throw new Error(err);
      });
  };
}

export function createMessageSuccess(message) {
  return {
    type: types.CREATE_NEW_MESSAGE_SUCCESS,
    message,
  };
}
// export function updateCourseSuccess(course) {
//   return {
//     type: types.UPDATE_COURSE_SUCCESS,
//     course,
//   };
// }

export function saveMessage(message) {
  return function (dispatch) {
    return messagesApi
      .saveMessage(message)
      .then((resp) => {
        console.log("inside save message thunk", resp.data);
        const savedMessage = { ...message, id: resp.data.name };
        dispatch(createMessageSuccess(savedMessage));
        // course.id
        //   ? dispatch(updateCourseSuccess(savedCourse))
        //   : dispatch(createCourseSuccess(savedCourse));
      })
      .catch((err) => {
        throw err;
      });
  };
}
