import React from "react";
import TextInput from "../common/TextInput";

const SignUpuForm = ({
  student,
  onSave,
  onChange,
  saving = false,
  errors = {},
  register = "Register",
}) => {
  return (
    <form onSubmit={onSave}>
      <h4>
        <small>Welcome To AnisTech Professional Registration Page</small>
      </h4>
      <h5>
        <small>Ready to get started?</small>
      </h5>
      {errors.onSave && (
        <div className="alert alert-danger" role="alert">
          {errors.onSave}
        </div>
      )}
      <TextInput
        name="fName"
        label="First Name"
        value={student.fName}
        onChange={onChange}
        error={errors.fName}
      />
      <TextInput
        name="lName"
        label="Last Name"
        value={student.lName}
        onChange={onChange}
        error={errors.lName}
      />
      <TextInput
        name="email"
        label="Email Address"
        value={student.email}
        onChange={onChange}
        error={errors.email}
      />
      <TextInput
        name="phone"
        label="Your Phone Number"
        value={student.phone}
        onChange={onChange}
        error={errors.phone}
      />

      <button
        type="submit"
        disabled={saving}
        className="btn btn-primary btn-lg"
      >
        {register}
      </button>
    </form>
  );
};

export default SignUpuForm;
