import { handleResponse, handleError } from "./apiUtils";
import axios from "axios";
const baseUrl =
  "https://anispro-user-database-default-rtdb.firebaseio.com/tasks.json";

export function getTasks() {
  return axios.get(baseUrl).then(handleResponse).catch(handleError);
}

export function saveTask(task) {
  let id = task?.id;
  const updateUrl = `https://anispro-user-database-default-rtdb.firebaseio.com/tasks/${id}.json`;
  if (id) {
    return axios
      .put(updateUrl, task)
      .then(handleResponse)
      .catch(handleError);
  } else {
    return axios.post(baseUrl, task).then(handleResponse).catch(handleError);
  }
}

export function deleteTask(key) {
  const deleteUrl = `https://anispro-user-database-default-rtdb.firebaseio.com/tasks/${key}.json`;
  return axios.delete(deleteUrl).then(handleResponse).catch(handleError);
}
