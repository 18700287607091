import * as types from "./actionTypes";
import * as coursesApi from "../../api/coursesApi";

export function loadCoursesSuccess(courses) {
  return {
    type: types.LOAD_COURSES_SUCCESS,
    courses,
  };
}

export function loadCourses() {
  return function (dispatch) {
    return coursesApi
      .getCourses()
      .then((resp) => {
        let arr = [];
        if (resp) {
          for (let key in resp.data) {
            arr.push({ ...resp.data[key], id: key });
          }
          dispatch(loadCoursesSuccess(arr));
        }
      })
      .catch((err) => {
        throw new Error(err);
      });
  };
}

export function createCourseSuccess(course) {
  return {
    type: types.CREATE_COURSE_SUCCESS,
    course,
  };
}
// export function updateCourseSuccess(course) {
//   return {
//     type: types.UPDATE_COURSE_SUCCESS,
//     course,
//   };
// }

export function saveCourse(course) {
  return function (dispatch) {
    return coursesApi
      .saveCourse(course)
      .then((resp) => {
        const savedCourse = { ...course, id: resp.data.name };
        dispatch(createCourseSuccess(savedCourse));
        // course.id
        //   ? dispatch(updateCourseSuccess(savedCourse))
        //   : dispatch(createCourseSuccess(savedCourse));
      })
      .catch((err) => {
        throw err;
      });
  };
}
