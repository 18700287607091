import React from "react";
import TextInput from "../common/TextInput";

const ContactUsForm = ({
  user,
  onSave,
  onChange,
  saving = false,
  errors = {},
}) => {
  return (
    <form onSubmit={onSave}>
      <h4>
        <small>Welcome To AnisTech Professional Contact Us Page</small>
      </h4>
      <h5>
        <small>Ready to get started?</small>
      </h5>
      {errors.onSave && (
        <div className="alert alert-danger" role="alert">
          {errors.onSave}
        </div>
      )}
      <TextInput
        name="fName"
        label="First Name"
        value={user.fname}
        onChange={onChange}
        error={errors.fname}
      />
      <TextInput
        name="lName"
        label="Last Name"
        value={user.lname}
        onChange={onChange}
        error={errors.lname}
      />
      <TextInput
        name="email"
        label="Email Address"
        value={user.email}
        onChange={onChange}
        error={errors.email}
      />
      <TextInput
        name="msg"
        label="Your Message"
        value={user.msg}
        onChange={onChange}
        error={errors.msg}
      />

      <button
        type="submit"
        disabled={saving}
        className="btn btn-primary btn-lg"
      >
        Submit
      </button>
    </form>
  );
};

export default ContactUsForm;
