import * as types from '../actions/actionTypes';
import { initialSate } from './initialState';

export default function UsersReducer(state = initialSate.users ,action) {
    switch (action.type) {
      case types.LOAD_USERS_SUCCESS:
        return action.users;
      case types.ADD_USER_SUCCESS:
        return [...state, { ...action.user }];
      case types.UPDATE_USER_SUCCESS:
        return state.map((user) => {
          return user.id === action.user.id ? action.user : user;
        });

      default:
        return state;
    }

}