
export const LOAD_USERS_SUCCESS = "LOAD_USERS_SUCCESS";
export const ADD_USER_SUCCESS = "ADD_USER_SUCCESS";
export const UPDATE_USER_SUCCESS = "UPDATE_USER_SUCCESS";
export const USER_LOG_IN = "USER_LOG_IN";
export const USER_LOG_OUT = "USER_LOG_OUT";
export const LOAD_MESSAGES_SUCCESS = "LOAD_MESSAGES_SUCCESS";
export const CREATE_NEW_MESSAGE_SUCCESS = "CREATE_NEW_MESSAGE_SUCCESS";
export const CREATE_COURSE_SUCCESS = "CREATE_COURSE_SUCCESS";
export const LOAD_COURSES_SUCCESS = "LOAD_COURSES_SUCCESS";
export const LOAD_CUSTOMER_MESSAGES_SUCCESS = "LOAD_CUSTOMER_MESSAGES_SUCCESS";
export const CREATE_NEW_CUSTOMER_MESSAGE_SUCCESS = "CREATE_NEW_CUSTOMER_MESSAGE_SUCCESS";
export const DELETE_CUSTOMER_MESSAGE_SUCCESS = "DELETE_CUSTOMER_MESSAGE_SUCCESS";
export const LOAD_STUDENTS_SUCCESS = "LOAD_STUDENTS_SUCCESS";
export const REGISTER_STUDENT_SUCCESS = "REGISTER_STUDENT_SUCCESS";
export const DELETE_STUDENT_SUCCESS = "DELETE_STUDENT_SUCCESS";
export const UPDATE_STUDENT_SUCCESS = "UPDATE_STUDENT_SUCCESS";
export const LOAD_TASKS_SUCCESS = "LOAD_TASKS_SUCCESS";
export const ADD_TASK_SUCCESS = "ADD_TASK_SUCCESS";
export const DELETE_TASK_SUCCESS = "DELETE_TASK_SUCCESS";
export const UPDATE_TASK_SUCCESS = "UPDATE_TASK_SUCCESS";
