import * as types from "./actionTypes";
import * as studentsApi from "../../api/studentsApi";

export function loadStudentsSuccess(students) {
  return {
    type: types.LOAD_STUDENTS_SUCCESS,
    students,
  };
}

export function loadStudents() {
  return function (dispatch) {
    return studentsApi
      .getStudents()
      .then((resp) => {
        let arr = [];
        if (resp) {
          for (let key in resp.data) {
            arr.push({ ...resp.data[key], id: key });
          }
          dispatch(loadStudentsSuccess(arr));
        }
      })
      .catch((err) => {
        throw new Error(err);
      });
  };
}

export function registerStudentSuccess(student) {
  return {
    type: types.REGISTER_STUDENT_SUCCESS,
    student,
  };
}
export function updateStudentSuccess(student) {
  return {
    type: types.UPDATE_STUDENT_SUCCESS,
    student,
  };
}

export function registerStudent(student) {
  return function (dispatch) {
    return studentsApi
      .saveStudent(student)
      .then((resp) => {
        const savedStudent = { ...student, id: resp.data.name };
        student.id
          ? dispatch(updateStudentSuccess(student))
          : dispatch(registerStudentSuccess(savedStudent));
      })
      .catch((err) => {
        throw err;
      });
  };
}

export function deleteStudentSuccess(id) {
  return {
    type: types.DELETE_STUDENT_SUCCESS,
    id,
  };
}

export function deleteStudent(id) {
  return function (dispatch) {
    return studentsApi
      .deleteStudent(id)
      .then((resp) => {
        if (resp) {
          dispatch(deleteStudentSuccess(id));
        }
      })
      .catch((err) => {
        throw new Error(err);
      });
  };
}
