import React from "react";

const PageNotFound = () => (
  <div
    className="container-fluid"
    style={{ textAlign: "center", marginTop: 50 }}
  >
    <div id="error-page">
      <h1>Oops! Page not found</h1>
      <p className="display-6">Please try later ...</p>
    </div>
  </div>
);

export default PageNotFound;
