// import { createStore, applyMiddleware } from "redux";
import { configureStore as createStore } from "@reduxjs/toolkit";
import rootReducers from "./reducers";
//enable logger for dev environment only
// import logger from "redux-logger";
import thunk from "redux-thunk";


export default function ConfigureStore(initState) {
  /*
  return createStore(rootReducers, initState, applyMiddleware(logger, thunk));
   this line of code is replaced with below implementation from redux/toolkit utility configureStore for simplification.
   check the import for more info and refer this : - https://redux.js.org/usage/configuring-your-store
   */

  // enable below for dev environment

  // return createStore({
  //   reducer: rootReducers,
  //   middleware: [logger, thunk],
  //   preloadedState: initState,
  // });

  // enable below for prod environment

  return createStore({
    reducer: rootReducers,
    middleware: [thunk],
    preloadedState: initState,
  });
}

