import React from "react";
import { Link } from "react-router-dom";

function Logo(){
    return (
      <Link to={`home`} className="navbar-brand">
        <img
          src="logo192.png"
          style={{ height: 100, width: 150 }}
          alt="AnisTech Logo"
        />
      </Link>
    );
}

export default Logo;