import { handleResponse, handleError } from "./apiUtils";
import axios from "axios";
const baseUrl =
  "https://anispro-user-database-default-rtdb.firebaseio.com/messages.json";

export function getMessages() {
  return axios.get(baseUrl).then(handleResponse).catch(handleError);
}

export function saveMessage(message) {
  return axios.post(baseUrl, message).then(handleResponse).catch(handleError);
  //   if (key) {
  //     return axios.put(baseUrl, value).then(handleResponse).catch(handleError);
  //   } else {
  //     return axios.post(baseUrl, value).then(handleResponse).catch(handleError);
  //   }
}

export function deleteMessage(key) {
  return axios.delete(baseUrl, key).then(handleResponse).catch(handleError);
}
