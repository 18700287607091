import React from "react";
import TextInput from "../common/TextInput";

const LoginForm = ({
  user,
  onSave,
  onChange,
  saving = false,
  errors = {},
}) => {
  return (
    <form onSubmit={onSave}>
      <h4>
        <small>Welcome To AnisTech Professional UIMS</small>
      </h4>
      <h5>Login Page</h5>
      {errors.onSave && (
        <div className="alert alert-danger" role="alert">
          {errors.onSave}
        </div>
      )}
      <TextInput
        name="email"
        label="Email"
        value={user.email}
        onChange={onChange}
        error={errors.email}
      />
      <TextInput
        name="pwd"
        label="Password"
        value={user.pwd}
        type="password"
        onChange={onChange}
        error={errors.pwd}
      />

      <button
        type="submit"
        disabled={saving}
        className="btn btn-primary btn-lg"
      >
        Login
      </button>
    </form>
  );
};


export default LoginForm;
