import { combineReducers } from "redux";
import courses from "./coursesReducer";
import users from "./usersReducer";
import userStatus from "./loginReducer";
import messages from "./messagesReducer";
import customerMessages from "./customerMessagesReducer";
import students from "./studentsReducer";
import tasks from "./tasksReducer";

const rootReducers = combineReducers({
  users,
  userStatus,
  messages,
  courses,
  customerMessages,
  students,
  tasks,
});

export default rootReducers;
