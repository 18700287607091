import * as types from "../actions/actionTypes";
import { initialSate } from "./initialState";

export default function studentsReducer(state = initialSate.students, action) {
  switch (action.type) {
    case types.LOAD_STUDENTS_SUCCESS:
      return action.students;
    case types.REGISTER_STUDENT_SUCCESS:
      return [...state, { ...action.student }];
    case types.UPDATE_STUDENT_SUCCESS:
      return state.map((stu) => {
        return stu.id === action.student.id ? action.student : stu;
      });
    case types.DELETE_STUDENT_SUCCESS:
      return state.filter((stu) => {
        return stu.id !== action.id;
      });
    default:
      return state;
  }
}
