import React, { useState, useEffect } from "react";
import UserForm from "./newUserForm";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { connect } from "react-redux";
import * as userAction from "../../../redux/actions/usersAction";
// eslint-disable-next-line no-useless-escape
var emailRegex = /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;

function AddNewUser({ addUser }) {
  const navigate = useNavigate();
  const [errors, setErrors] = useState();
  const [saving, setSaving] = useState(true);
  const [user, setuser] = useState({
    fullName: "",
    email: "",
    pwd: "",
    role: "staff",
  });

  useEffect(() => {
    if (
      user.fullName !== "" &&
      user.pwd !== "" &&
      user.email !== "" &&
      user.role !== ""
    ) {
      setSaving(false);
    } else {
      setSaving(true);
    }
  }, [user.fullName, user.email, user.pwd, user.role]);

  function onChange(event) {
    let { name, value } = event.target;

    setuser((prev) => {
      return {
        ...prev,
        [name]: value,
      };
    });
  }

  function validateEmail(email) {
    //Validates the email address
    return emailRegex.test(email);
  }

  function formIsValid() {
    setSaving(false);
    const { fullName, pwd, email, role } = user;
    const errors = {};

    if (!fullName || fullName.trim() === "")
      errors.fullName = "Full Name is required.";
    if (!pwd || pwd.trim() === "") errors.pwd = "Password is required";
    if (!email || email.trim() === "") errors.email = "Email is required";
    if (!validateEmail(email)) errors.email = "Invalid Email";
    if (!role || role.trim() === "") errors.role = "Role is required";

    setErrors(errors);
    // Form is valid if the errors object still has no properties
    return Object.keys(errors).length === 0;
  }

  function onSave(event) {
    event.preventDefault();
    if (!formIsValid()) return;
    addUser(user);
    toast.success(`New User Added Successfully.`);
    setuser({
      fullName: "",
      email: "",
      pwd: "",
      role: "",
    });
    navigate("/dashboard");
  }

  return (
    <div className="container-fluid" style={{ width: "40%", marginTop: 50 }}>
      <UserForm
        user={user}
        onSave={onSave}
        onChange={onChange}
        saving={saving}
        errors={errors}
        register={"Add User"}
      />
    </div>
  );
}
function mapStateToProps(state, ownProps) {
  return {};
}

const mapDispatchToProps = {
  addUser: userAction.addUser,
};
export default connect(mapStateToProps, mapDispatchToProps)(AddNewUser);
