import React, { useEffect } from "react";
import { connect } from "react-redux";
import * as customerAction from "../../redux/actions/customerMessageAction";
import { toast } from "react-toastify";

function CustomerMessages({
  customerMessages,
  loadCustomerMessages,
  deleteCustomerMessage,
}) {
  useEffect(() => {
    loadCustomerMessages();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  function hundleDelete(id) {
    const del = window.confirm(` Do you really want to delete this message ?`);
    if (del) {
      deleteCustomerMessage(id);
      toast.success(" Message Deleted Successfully");
    } else {
      return;
    }
  }
  return (
    <div style={{ marginTop: 20 }} className="row">
      {customerMessages ? (
        customerMessages?.map((msg) => {
          <h5>msg.fName</h5>;
          return (
            <div
              className="card"
              style={{ width: "30%", marginRight: 5, marginTop: 5 }}
              key={msg?.id}
            >
              <div className="card-header">Message Detail</div>
              <div className="card-body">
                <h5 className="card-title">
                  Full Name : {msg?.fName} {msg?.lName}
                </h5>
                <h5 className="card-title">Email : {msg?.email}</h5>
                <p className="card-text">Message : {msg?.msg}</p>
                <button
                  className="btn btn-danger"
                  onClick={() => {
                    hundleDelete(msg.id);
                  }}
                >
                  Delete Message
                </button>
              </div>
            </div>
          );
        })
      ) : (
        <h5> Loading messages ...</h5>
      )}
    </div>
  );
}

function mapStateToProps(state, ownProps) {
  return {
    customerMessages: state.customerMessages,
  };
}
const mapDispatchToProps = {
  loadCustomerMessages: customerAction.loadCustomerMessages,
  deleteCustomerMessage: customerAction.deleteCustomerMessage,
};
export default connect(mapStateToProps, mapDispatchToProps)(CustomerMessages);
