import React from "react";
import CoursePage from '../courses/CoursesPage'
function ServicesPage() {

  return (
    <div className="container-fluid" style={{ marginTop: 20 }}>
      <CoursePage/>
      {/* Service component should have 3 things :-
        -Trainings we are offering 
        - List Of support we are providing 
        - Other services( like job Placement) */}
    </div>
  );
}

export default ServicesPage;
