import React from "react";
import Navigation from "./Navigation";
import Logo from "./Logo";

function Header() {
  return (
    <nav className="navbar navbar-dark bg-ligth navbar-expand-lg bg-body-tertiary">
      <div className="container-fluid">
        <Logo/>
        <Navigation />
      </div>
    </nav>
  );
}

export default Header;
