import * as types from "../actions/actionTypes";
import { initialSate } from "./initialState";

export default function customerMessagesReducer(state = initialSate.customerMessages, action) {
  switch (action.type) {
    case types.LOAD_CUSTOMER_MESSAGES_SUCCESS:
      return action.messages;
    case types.CREATE_NEW_CUSTOMER_MESSAGE_SUCCESS:
      return [...state, { ...action.message }];
      case types.DELETE_CUSTOMER_MESSAGE_SUCCESS:
        return state.filter((msg) => {return msg.id !== action.id})
    default:
      return state;
  }
}
