import React, { useState, useEffect } from "react";
import ContactUsForm from "./ContactUsForm";
import { useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import * as customerAction from "../../redux/actions/customerMessageAction";
import { toast } from "react-toastify";

function ContactUsPage({ saveCustomerMessage }) {
  const navigate = useNavigate();
  const [errors, setErrors] = useState();
  const [saving, setSaving] = useState(true);
  const [user, setUser] = useState({
    fName: "",
    lName: "",
    email: "",
    msg: "",
  });
  useEffect(() => {
    if (
      user.fName !== "" &&
      user.lName !== "" &&
      user.email !== "" &&
      user.msg !== ""
    ) {
      setSaving(false);
    } else {
      setSaving(true);
    }
  }, [user.fName, user.lName, user.email, user.msg]);

  function onChange(event) {
    let { name, value } = event.target;

    setUser((prev) => {
      return {
        ...prev,
        [name]: value,
      };
    });
  }

  function formIsValid() {
    setSaving(false);
    const { fName, lName, email, msg } = user;
    const errors = {};

    if (!fName) errors.fName = "First Name is required.";
    if (!lName) errors.lName = "Last Name is required";
    if (!email) errors.email = "Email is required";
    if (!msg) errors.msg = "Message is required";

    setErrors(errors);
    // Form is valid if the errors object still has no properties
    return Object.keys(errors).length === 0;
  }

  function onSave(event) {
    event.preventDefault();
    if (!formIsValid()) return;
    saveCustomerMessage(user);
    toast.success(`Message sent successfully.
     Some one will contact you soon. Thank you for choosing AnisTech Professional`)
    //notification component should be implemented here
    setUser({
      fName: "",
      lName: "",
      email: "",
      msg: "",
    });
    navigate("/home");
  }

  return (
    <div className="container-fluid" style={{ width: "40%", marginTop: 50 }}>
      <ContactUsForm
        user={user}
        onSave={onSave}
        onChange={onChange}
        saving={saving}
        errors={errors}
      />
    </div>
  );
}

function mapStateToProps(state,ownprops) {
  return {}
}
const mapDispatchToProps = {
  saveCustomerMessage : customerAction.saveCustomerMessage,
};
export default connect(mapStateToProps,mapDispatchToProps)(ContactUsPage);
